import {getCookie, getCookieLogin} from '../Cookie';

/**
 * Needle examples
 * agencyId
 */
export const getAccountData = needle => {
  let accountData = null;
  let check_new = getCookie('encode__process__new');
  if (check_new !== undefined) {
    accountData = getCookieLogin(process.env.REACT_APP_ACCOUNT_DATA);
  } else {
    accountData = getCookie(process.env.REACT_APP_ACCOUNT_DATA);
  }
  if (accountData) {
    accountData = JSON.parse(accountData);
    return accountData[needle];
  }
  return false;
};
